.content {
    margin-top: 100px;
    margin-bottom: 10px;
}
html {
    min-height: 100vh;
    background: #f5f5f5;
}
body {
    background-color: transparent !important;
}
.right-10 {
    right: 10px;
}
a {
    font-weight: bold !important;
    text-transform: none !important;
    text-decoration: none !important;
}
a:hover {
    color: #ec6730 !important;
    opacity: 0.8 !important;
}
tr {
    cursor: pointer;
}
.btn.btn-gastro {
    color: #fff;
    background-color: #EC6730;
    border-color: #EC6730;
}
.btn.btn-gastro:hover {
    color: #fff;
    background-color: #f38253;
    border-color: #f38253;
}
.css-1nrlq1o-MuiFormControl-root {
    vertical-align: baseline !important;
}
.MuiPaper-root * {
    font-family: inherit !important;
    margin-bottom: 0 !important;
}

.MuiPaper-root .MuiTableFooter-root {
    margin-bottom: 0 !important;
}
.formPage .MuiInputBase-root,
.formPage .MuiFormControl-root {
    width: 75%;
}

.customPagination a {
    color:  #EC6730;
}
.page-item.active a {
    background-color: #EC6730;
    color: #FFFFFF;
    border-color: #EC673050;
}
.page-item.active a:hover {
    background-color: #EC673050;
    color: #FFFFFF;
    border-color: #EC673050;
}
.modal {
    z-index: 9999 !important;
}
.my-color {
    color: #EC6730;
}

.image-content{
    position: relative;
}
.image-content img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
}
.avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
}
.fake-link {
    cursor: pointer;
    color: #EC6730;
    font-weight: bold;
}
.fake-link:hover {
    opacity: 0.8;
}
.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
tr.click-disabled {
    background-color: #efefef;
    cursor: inherit;
}
.filter-name-planning{
    border: 1px solid #ced4da;
     border-Radius: 8px;
     margin-left: 15px;
    
}
.filter-name-planning:active{
    border: 1px solid #ced4da;
}
.txt-planning{
    font-size: 18px;
}
.checkbox-custom {
    background: #fff;
    border: 2px solid #ddd;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
}


.scroll::-webkit-scrollbar {
    width: 20px;
  }
  
  /* Track */
  .scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .scroll::-webkit-scrollbar-thumb {
    background: #EC6730; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .scroll::-webkit-scrollbar-thumb:hover {
    background: #EC6730; 
  }

  .css-xya8j6-MuiButtonBase-root-MuiButton-root.TodayButton-button:first-of-type  {
    color: #aaa;
    border-color: #aaa;
}
.css-xya8j6-MuiButtonBase-root-MuiButton-root.TodayButton-button:first-of-type:hover{
    background-color: #aaa;
    color: #EC6730;
}
.css-vnscjq-MuiButtonBase-root-MuiButton-root.OpenButton-textButton, .css-71a9mb-MuiButtonBase-root-MuiIconButton-root.NavigationButton-button,.css-71a9mb-MuiButtonBase-root-MuiIconButton-root.NavigationButton-button {
    color: #aaa;

}
.css-11ngvp6 .Content-lens{
   display: none;

}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2.css-1o7apob-MuiGrid-root{
    display: none;
}